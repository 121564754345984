import logo from './logo.svg';
import './App.css';
import Slider from './Components/Slider/Slider';
import About from './Components/About/About';
import Token from './Components/Tokenomics/Token';
import Hero from './Components/Home/Home';

function App() {
  return (
    <div className='bg1 max-w-[100dvw] min-h-[100vh] overflow-x-clip'>
      <Hero/>
      <Slider/>
      <About/>
      <Slider/>
      <Token/>
    </div>
  );
}

export default App;
