import React from 'react'

const Token = () => {
    return (
        <div>
            <section class="text-gray-600 body-font">
                <div class="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
                    <img class="lg:w-2/6 md:w-3/6 w-5/6 mb-10 object-cover object-center rounded disco-dance" alt="hero" src={require('../../Assets/token.png')} />
                    <div class="text-center lg:w-2/3 w-full">
                        <h1 class="title-font text-[30px] sm:text-[30px] mnd:text-[80px] text-white mb-4 font-bold ">Tokenomics</h1>
                        <p class="mb-8 leading-relaxed text-[24px] text-white"> No TAX</p>
                        <p class="mb-8 leading-relaxed text-[24px] text-white"> LP BURNED 🔥</p>
                        <p class="mb-8 leading-relaxed text-[24px] text-white"> 1 Billion Supply</p>


                        <div class="flex justify-center">
                            <a href='https://t.me/departmentofpepe'>
                            <button class="inline-flex text-white bg-[#5D8A40] border-0 py-2 px-6 focus:outline-none hover:bg-[#5D8A40] rounded text-lg">Buy Now</button>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Token