import React from 'react';

const Hero = () => {
    return (
        <div className="relative w-full h-[100vh] overflow-hidden">
            {/* Background Video */}
            <video
                autoPlay
                loop
                muted
                className="absolute top-0 left-0 w-full h-full object-cover"
            >
                <source src={require('../../Assets/vid.mp4')} type="video/mp4" />
            </video>

            {/* Dark Overlay */}
            <div className="absolute inset-0 bg-black opacity-50 z-0"></div>

            {/* Overlay Content */}
            <div className="relative z-10 flex flex-col items-center justify-center h-full text-center text-white p-6">
                <img className='jitter' src={require('../../Assets/mupe.png')} />
                <h1 className="text-[80px] md:text-[122px] font-bold mb-1">D.O.<span className='text-[#5D8A40]'>P</span>.E</h1>
                <p className="text-sm md:text-2xl max-w-full mb-4">
                CA: 0xac49D899551aCef6c49B16E6D2AF3154f7791993        </p>

                <div className='flex justify-center items-center gap-4'>
                    <a href='https://x.com/deptpepe'>
                        <button className=" px-6 py-3 bg-[#476e2d] hover:bg-[#5D8A40] text-white rounded-lg transition">
                            Twitter
                        </button>
                    </a>
                    <a href='https://t.me/departmentofpepe'>
                        <button className=" px-6 py-3 bg-[#476e2d] hover:bg-[#5D8A40] text-white rounded-lg transition">
                            Telegram
                        </button>
                    </a>

                </div>
            </div>
        </div>
    );
};

export default Hero;
