import React from 'react'
import Marquee from "react-fast-marquee";

const Slider = () => {
    return (
        <div className='bg-[#000000] w-[100vw] border-y-4 border-[#ffffff]'>
            <Marquee>
                <div className='h-[90px] md:h-[140px] flex justify-center items-center gap-8 md:gap-32'>
                    <div className='flex items-center gap-8'>
                        <img className='h-[70px] md:h-[80px]' src={require('../../Assets/head.png')} />
                        <img className='h-[70px] md:h-[80px]' src={require('../../Assets/head.png')} />
                        <img className='h-[70px] md:h-[80px]' src={require('../../Assets/head.png')} />
                        <img className='h-[70px] md:h-[80px]' src={require('../../Assets/head.png')} />
                        <img className='h-[70px] md:h-[80px]' src={require('../../Assets/head.png')} />
                        <img className='h-[70px] md:h-[80px]' src={require('../../Assets/head.png')} />
                        <img className='h-[70px] md:h-[80px]' src={require('../../Assets/head.png')} />
                        <img className='h-[70px] md:h-[80px]' src={require('../../Assets/head.png')} />
                        <img className='h-[70px] md:h-[80px]' src={require('../../Assets/head.png')} />
                        <img className='h-[70px] md:h-[80px]' src={require('../../Assets/head.png')} />
                        <img className='h-[70px] md:h-[80px]' src={require('../../Assets/head.png')} />
                        <img className='h-[70px] md:h-[80px]' src={require('../../Assets/head.png')} />
                        <img className='h-[70px] md:h-[80px]' src={require('../../Assets/head.png')} />
                        <img className='h-[70px] md:h-[80px]' src={require('../../Assets/head.png')} />
                        <img className='h-[70px] md:h-[80px]' src={require('../../Assets/head.png')} />
                        <img className='h-[70px] md:h-[80px]' src={require('../../Assets/head.png')} />
                        <img className='h-[70px] md:h-[80px]' src={require('../../Assets/head.png')} />
                        <img className='h-[70px] md:h-[80px]' src={require('../../Assets/head.png')} />
                        <img className='h-[70px] md:h-[80px]' src={require('../../Assets/head.png')} />
                        <img className='h-[70px] md:h-[80px]' src={require('../../Assets/head.png')} />
                        <img className='h-[70px] md:h-[80px]' src={require('../../Assets/head.png')} />
                        <img className='h-[70px] md:h-[80px]' src={require('../../Assets/head.png')} />
                        <img className='h-[70px] md:h-[80px]' src={require('../../Assets/head.png')} />
                        <img className='h-[70px] md:h-[80px]' src={require('../../Assets/head.png')} />
                        <img className='h-[70px] md:h-[80px]' src={require('../../Assets/head.png')} />
                        <img className='h-[70px] md:h-[80px]' src={require('../../Assets/head.png')} />
                        <img className='h-[70px] md:h-[80px]' src={require('../../Assets/head.png')} />

                        <img className='h-[70px] md:h-[80px]' src={require('../../Assets/head.png')} />
                    </div>
                </div>
            </Marquee>
        </div>
    )
}

export default Slider