import React from 'react'

const About = () => {
    return (
        <div className='bg1'>
            <section class="text-gray-600 body-font">
                <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
                    <div class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                        <h1 class="title-font sm:text-[40px] text-[80px] text-white mb-4 font-bold ">ABOUT D.O.P.E
                            <br class="hidden lg:inline-block" />
                        </h1>
                        <p class="mb-8 leading-relaxed text-[white] text-[24px]">At the Department Of Pepe Economics, we take research quite seriously. We study basic memeanomics and how it applies to the real world.

                            With this newly appointed department, we will make sure the global population understand the basics of Pepe and the economics revolving around Pepe.</p>
                        {/* <div class="flex justify-center">
                            <button class="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">Button</button>
                            <button class="ml-4 inline-flex text-gray-700 bg-gray-100 border-0 py-2 px-6 focus:outline-none hover:bg-gray-200 rounded text-lg">Button</button>
                        </div> */}
                    </div>
                    <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                        <img class="  animate-spin" alt="hero" src={require('../../Assets/head.png')} />
                    </div>
                </div>
            </section>
        </div>
    )
}

export default About